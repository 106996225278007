

import http from '../axios'

//最新报价的列表 带联合查询
export const getQuoteData = (params) => {
    /*   return http.post('/home/quote', params) */
    return http.post('/offers', params)
}


// 点击更多报价
export const getMoreCompanyOffer = params => {
    return http.post('/offers/more', params)
}

// 报价详情页
export const getQuoteDetailData = params => {
    return http.post('/info/price', params)
}

// 最新报价搜索接口
export const $searchQuoteForInfo = params => {
    return http.post('/search', params)
}
