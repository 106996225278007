

import _ from 'lodash'

// 最新报价
export default function moreHandler(originArr = [], injectArr = [], id) {
    const origin = _.cloneDeep(originArr)
    let cur = origin.find(v => v.id == id)
    cur.action = 'puck' // 点击当前数的行为 更改为收起
    injectArr = injectArr.map(v => {
        v.isChildren = true
        return v
    }) //给获取到的获取标记一下
    injectArr = handerData(injectArr)
    let index = origin.indexOf(cur)
    origin.splice(index, 1, cur, ...injectArr)
    return origin
}

function handerData(arr = []) {
    const result = []
    arr.forEach(item => {
        const company = item.company //拿到公司的这个属性
        delete item.company // 删除公司的这个属性
        const regions = item.regions // 地区
        delete item.regions // 删除
        let temp = {
            ...company,
            offers: {
                ...item
            },
            ...regions,
            isChildren: true,

        }
        delete temp.action
        result.push(temp)
    })
    return result
}

export const handerDataToFormat = arr => {
    const result = []
    arr.forEach(item => {
        const company = item.company //拿到公司的这个属性
        delete item.company // 删除公司的这个属性
        const regions = item.regions // 地区
        delete item.regions // 删除
        let temp = {
            ...company,
            offers: {
                ...item
            },
            ...regions,
        }
        temp.action = 'more'
        result.push(temp)
    })
    return result
}


// 贸易商机的more 
export const moreNewsHandler = (originArr = [], injectArr = [], id, oindex) => {
    console.log(originArr, injectArr, id, oindex);
    const origin = _.cloneDeep(originArr)
    let cur = origin.find((v, i) => i == oindex)
    cur.action = 'puck' // 点击当前数的行为 更改为收起
    injectArr = injectArr.map(v => {
        v.isChildren = true
        return v
    }) //给获取到的获取标记一下
    // injectArr = handerData(injectArr)
    let index = origin.indexOf(cur)
    origin.splice(index, 1, cur, ...injectArr)
    return origin
}

/* function handlerNews(arr = []) {
    const result = []
    arr.forEach(item => {
        const company = item.company //拿到公司的这个属性
        delete item.company // 删除公司的这个属性
        const regions = item.regions // 地区
        delete item.regions // 删除
        let temp = {
            ...company,
            offers: {
                ...item
            },
            ...regions,
            isChildren: true,

        }
        delete temp.action
        result.push(temp)
    })

    return result
} */