

import { defineStore } from 'pinia'
import { getClassIfy, getClassifyArr, getChildrenData } from "@/api/modules/home_api.js";
import { filterDefault } from '@/filters/default_cover.js'
import _ from 'lodash'
import {
    formArr
} from '@/utils/arr.methods.js'
export const useClassIfy = defineStore('classIfy', {
    state() {
        return {
            req: false,
            classIfy: [], // 分类
            nameArr: [], // 品名
            specList: [], // 规格
            textureList: [], //材质
            curTbasId: '', // 当前tabs id
            params: {
            }, // 携带参数
            loading: false, //loading
        }
    },
    /* 永久行存储 */
    persist: {
        enabled: true, // 开启存储
        // **strategies: 指定存储位置以及存储的变量都有哪些,该属性可以不写，不写的话默认是存储到sessionStorage里边，默认存储state里边的所有数据**
        strategies: [
            { storage: sessionStorage, paths: ['classIfy', 'nameArr', 'specList', 'textureList'] }
            // storage 存储到哪里 sessionStorage/localStorage
            // paths是一个数组,要存储缓存的变量,当然也可以写多个
            // paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
        ]
    },
    actions: {
        // 初始化
        async initClassIfy(flag,) {
            if (!flag) {
                if (this.classIfy.length) return
            }
            try {
                if (!flag) {
                    if (this.req) {
                        return
                    }
                    this.req = true
                    this.loading = true
                }

                let pRes = await getClassIfy()
                let {
                    status,
                    data
                } = pRes
                if (status === 1) {
                    data = handeler(data)
                    this.classIfy = data.classIfy
                    this.loading = false
                    this.nameArr = data.nameArr
                    this.textureList = data.textureList.filter(v => v)
                    this.specList = data.specList
                    //     this.initspecList(data)

                } else {
                    throw Error('意外的错误')
                }
            } catch (error) {
                console.log(error);
            }
        },

        // 获取单个的次分类
        async getNameArr(id) {
            if (!id) {
                if (this.curTbasId == 'all') {
                    this.initClassIfy(true)
                    return
                } else {
                    console.log(this.curTbasId);
                    id = this.curTbasId
                }
            }
            this.params = {} //每次点击单个的次分类，之前的参数就作废了
            let res = await getChildrenData({
                type: id
            })
            let {
                status,
                data
            } = res
            if (status === 1) {
                this.nameArr = data // 品名切换
            }
            this.updataSpecList({
                typeId: id
            })
        },
        //根据 pinia 管理的 params 进行发送请求
        async searchClassify() {
            if (this.params.curTbasId === 'all') {
                this.params.curTbasId = ''
            }
            let res = await getClassifyArr(this.params)
            let {
                status,
                data
            } = res
            if (status === 1) {
                let {
                    steelMillInfo,
                    textureInfo
                } = data
                this.textureList = Myflat(textureInfo)
                this.specList = Myflat(steelMillInfo)
            }
        },

        // 初始化规格参数
        async initspecList(data = []) {
            const request = data.map(v => {
                return getClassifyArr({
                    typeId: v.id
                })
            })
            try {
                const responseArr = await Promise.all(request)
                let textureInfo = [], // 材质
                    steelMillInfo = []; // 规格
                responseArr.forEach(v => {
                    textureInfo.push(v.data.textureInfo)
                    steelMillInfo.push(v.data.steelMillInfo)
                })
                this.textureList = Myflat(textureInfo)
                this.specList = Myflat(steelMillInfo)
            } catch (error) {
                console.log(error);
            }
        },


        // 获取单个的规格参数
        async updataSpecList(params) {
            let res = await getClassifyArr(params)
            let {
                status,
                data
            } = res
            if (status === 1) {
                this.specList = formArr(data.steelMillInfo)
                this.textureList = formArr(data.textureInfo)
            }
        },

        // 获取子数据
        async initChildrenData(list = []) {
            const requestArr = list.map(v => {
                return getChildrenData({
                    type: v.id
                })
            })
            let response = await Promise.all(requestArr)
            let result = []
            if (response.length > 0) {
                list.forEach(item => {
                    response.forEach(v => {
                        if (item.id === v.data[0].parent_id) {
                            item.children = v.data
                            result.push(...v.data)
                        }
                    })
                })
                this.nameArr = result
                this.classIfy = list
                this.loading = false
            } else {
                ElMessage.error('网络发生了一点错误，请稍后重试~')
            }
        },

    }
})




// 数组去重并扁平化
const Myflat = arr => {
    arr = arr.flat(Infinity)
    return [...new Set(arr)]
}


// 数据处理
function handeler(arr = []) {
    arr = Myflat(arr)
    const nameArr = [] // nameArr
    let textureList = [] // 材质数组
    let specList = []  // 规格数组
    let classIfy = arr.map(item => {
        item.img = filterDefault(item.cover, item.id)
        item.children = item.cate_two
        delete item.cate_two
        item.children?.forEach(v => {
            v.type_title = v.text
        })
        nameArr.push(...item.children)
        textureList.push(..._.values(item.texture))
        specList.push(..._.values(item.spec))
        textureList.forEach(item => {
            item.type_title = item.texture
        })
        specList.forEach(item => {
            item.type_title = item.spec
        })

        return item
    })
    textureList = getFlatArr(textureList, 'type_title');
    specList = getFlatArr(specList, 'type_title');

    return {
        classIfy,
        nameArr: Myflat(nameArr),
        textureList: Myflat(textureList),
        specList: Myflat(specList),
    }
}




// 根据 k 取出 数组中的数据
function getFlatArr(arr = [], key) {
    return arr.map(v => {
        return v[key]
    })
}