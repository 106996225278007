
// 过滤默认的图片
export const filterDefault = (cover, type) => {
    let imgUrl = ''
    switch (type) {
        case 18:
            imgUrl = 'icon-yly_guancai' //管材
            break;
        case 36:
            imgUrl = 'icon-yly_rejuan' // 板材
            break;
        case 89:
            imgUrl = 'icon-yly_xinggang' // 型材
            break;
        case 90:
            imgUrl = 'icon-yly_youtegang'  // 棒材s
            break
        case 91:
            imgUrl = 'icon-xiancai4' // 咸菜
            break
        case 92:
            imgUrl = 'icon-yly_zhongban' // 制品
            break
    }
    return imgUrl
}